import { useEffect } from 'react';
import { SelectProps, Text } from '@chakra-ui/react';
import axios, { AxiosRequestConfig } from 'axios';
import { useCompositeState } from 'ds4biz-core';
import { IField } from 'constants/form';
import { BaseSelect } from 'shared/Form/BaseSelect';

export interface AsyncSelectProps extends SelectProps {
  field: IField & { url: string; options: any[]; config?: AxiosRequestConfig };
}

export function AsyncSelect({ field, size = 'sm', ...rest }: AsyncSelectProps) {
  const state: { error: boolean; loading: boolean; options: any[] } = useCompositeState({
    error: false,
    loading: true,
    options: [],
  });

  useEffect(() => {
    if (field.url) {
      state.loading = true;

      axios
        .get(field.url, field.config || {})
        .then(({ data }) => {
          state.options = data;
          state.loading = false;
        })
        .catch(() => (state.error = true));
    }
  }, [field.url]);

  return (
    <>
      {state.error ? (
        <Text color="red.300" as="p" mt={2}>
          Error during retrieve informations
        </Text>
      ) : (
        <BaseSelect field={field} size={size} options={state.options} isLoading={state.loading} {...rest} />
      )}
    </>
  );
}
