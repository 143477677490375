import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IField } from 'constants/form';
import { gatewayCrud } from 'services/api';
import { BaseSelect } from 'shared/Form/BaseSelect';

export function GatewayDependent({
  field,
}: {
  field: IField & {
    fragment: string;
    parent: string;
  };
}) {
  const { watch } = useFormContext();

  const [options, setOptions] = useState<string[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const parentWatch = watch(field.parent);

  useEffect(() => {
    if (parentWatch) {
      setLoading(true);

      gatewayCrud.getOne<string[]>(parentWatch, field.fragment).then((data) => {
        setOptions(data);
        setLoading(false);
      });
    }
  }, [parentWatch]);

  return <>{parentWatch && <BaseSelect field={field} options={options} isLoading={loading} />}</>;
}
