import { useEffect, useMemo } from 'react';
import { Button, ButtonGroup } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { Box, Text } from '@chakra-ui/layout';
import { ModalFooter } from '@chakra-ui/modal';
import { Switch } from '@chakra-ui/switch';
import { Dialog, List, ListItem, useCompositeState } from 'ds4biz-core';
import { getFileExtension } from 'utils/misc';
import { useLocalStorage } from '@rehooks/local-storage';
import { EDITOR_STORAGE } from 'constants/localStorage';

interface Application {
  iconBg: string;
  title: string;
  description: string;
  value: string;
  extensions: string[];
  disabled?: boolean;
  icon?: boolean;
}

const items: Application[] = [
  {
    iconBg: '#92E9D2',
    title: 'Plain text editor',
    description: 'A normal text editor',
    value: 'text',
    extensions: ['*', 'txt', 'doc'],
  },
  {
    iconBg: '#7AE4C8',
    title: 'JSON viewer',
    description: 'A JSON viewer',
    value: 'json',
    extensions: ['json', 'metadata', 'project'],
  },
  {
    iconBg: '#FDCE69',
    title: 'Image viewer',
    description: 'Image viewer',
    value: 'image_view',
    extensions: ['png', 'jpg', 'jpeg', 'gif', 'tiff'],
  },
  {
    iconBg: '#3182ce',
    title: 'EDA',
    description: 'Open csv files and plot charts',
    value: 'csv',
    extensions: ['csv'],
  },
];

function getApplications(value: string, extension: string) {
  const apps = extension
    ? items
        .map((item) => {
          const enabled = item.extensions.includes(extension) || item.extensions.includes('*');

          const deafult = value && item.value === value;
          const title = deafult ? `${item.title} (default)` : item.title;

          return {
            ...item,
            disabled: !enabled,
            deafult,
            title,
          };
        })
        .filter((item) => !item.disabled)
    : items;

  return apps;
}

interface ApplicationChooserProps {
  isOpen: boolean;
  onOpenApplication: (application?: string) => void;
  onClose: () => void;
  item: { name: string } | undefined;
}

export function ApplicationChooser({ isOpen, onOpenApplication, onClose, item }: ApplicationChooserProps) {
  const state: { value: string; ext: string; asDefault: boolean; isLoading: boolean } = useCompositeState({
    value: '',
    ext: '',
    asDefault: false,
    isLoading: false,
  });

  const [storedEditor, setStoredEditor] = useLocalStorage<{ [extension: string]: string }>(EDITOR_STORAGE, {});

  const availableApplications = useMemo(() => getApplications(storedEditor[state.ext], state.ext), [item, state.ext]);

  useEffect(() => {
    if (item) {
      const ext = getFileExtension(item.name);

      if (ext) {
        state.value = storedEditor[ext];
        state.ext = ext;
      }
    }
  }, [item]);

  function handleSave() {
    if (state.asDefault) {
      setStoredEditor({ ...storedEditor, [state.ext]: state.value });
    }

    switch (state.value) {
      case 'image_annotator': {
        onOpenApplication('image_annotator');
        break;
      }
      case 'image_view': {
        onOpenApplication('image_view');
        break;
      }
      case 'predictor': {
        onOpenApplication('predictor');
        break;
      }
      case 'text_annotator': {
        onOpenApplication('text_annotator');
        break;
      }
      case 'text': {
        onOpenApplication('text');
        break;
      }
      case 'csv': {
        onOpenApplication('csv');
        break;
      }
      case 'json': {
        onOpenApplication('json');
        break;
      }
      default: {
        onOpenApplication();
      }
    }

    state.asDefault = true;
  }

  return (
    <Dialog
      title={`Open ${state.ext} files with...`}
      isOpen={isOpen}
      onClose={onClose}
      autoFocus
      modalFooter={
        <ModalFooter
          px={8}
          py={4}
          d="flex"
          borderTop="1px solid"
          justifyContent="space-between"
          borderColor={useColorModeValue('gray.100', 'gray.700')}
        >
          <FormControl w="full" display="flex" alignItems="center">
            <Switch
              id="default-app"
              size="sm"
              isChecked={state.asDefault}
              onChange={() => (state.asDefault = !state.asDefault)}
            />
            <FormLabel htmlFor="default-app" cursor="pointer" mb={0} ml={2} pb="px" fontSize="sm">
              Set as default
            </FormLabel>
          </FormControl>

          <ButtonGroup variant="ghost" size="sm" mr={-2}>
            <Button onClick={onClose}>Close</Button>
            <Button onClick={handleSave} isDisabled={!state.value}>
              Open
            </Button>
          </ButtonGroup>
        </ModalFooter>
      }
    >
      <Box m="0 -0.75rem">
        {availableApplications &&
          (availableApplications.length > 0 ? (
            <List>
              {availableApplications.map((app) => (
                <ListItem
                  key={app.value}
                  title={app.title}
                  icon={app.icon}
                  iconBg={app.iconBg}
                  description={app.description}
                  selected={app.value === state.value}
                  isDisabled={app.disabled}
                  onClick={() => (state.value = app.value)}
                  onDoubleClick={handleSave}
                />
              ))}
            </List>
          ) : (
            <Text color="gray.500" textAlign="center" my="2">
              No applications found
            </Text>
          ))}
      </Box>
    </Dialog>
  );
}
