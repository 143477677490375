import { useState } from 'react';
import { IconButton, Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Center, Text } from '@chakra-ui/layout';
import { Table, TableCaption, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table';
import { saveAs } from 'file-saver';
import { RiArrowUpLine, RiFile2Line } from 'react-icons/ri';
import {
  BsCloudy,
  BsCardImage,
  BsFileExcelFill,
  BsFileEarmarkRuled,
  BsTextLeft,
  BsFonts,
  BsFillFilePptFill,
  BsFileZipFill,
  BsFileWordFill,
  BsEnvelope,
  BsPlayBtn,
  BsXDiamond,
  BsXDiamondFill,
  BsBezier2,
  BsCodeSlash,
  BsFillFolderFill,
} from 'react-icons/bs';
import { VscJson } from 'react-icons/vsc';
import { SiAdobeacrobatreader } from 'react-icons/si';
import { AsyncLoader } from 'shared/AsyncLoader';
import { ContextMenu } from 'shared/ContextMenu';
import { EditableItem } from 'shared/EditableItem';
import { getFileExtension } from 'utils/misc';
import { orchestratorCrud } from 'services/api';
import { ApplicationChooser } from './ApplicationChooser';
import { FileConverter } from './FileConverter';
import { Item } from './types';

interface FileSystemProps {
  files: Item[];
  selected: string[];
  copied: {
    cutting: boolean | undefined;
    items: Item[];
  };
  showBackButton?: boolean;
  onFileRename: (filename: string, newFilename: string) => void;
  onFileMove: (file: Item, cutting?: boolean) => void;
  onFileExtract: (file: Item) => void;
  onFileDelete: (file: string[]) => void;
  onFilePaste: (file: { isDir: true; path: string }) => void;
  onFileOpen: ({ item, editor }: { item: Item; editor?: string }) => void;
  onFileConvert: ({ item, from, to }: { item: Item; from: string; to: string }) => void;
  onFileCompress: (file: Item) => void;
  onFileClick: (file: Item) => void;
  onFileDoubleClick: (file: Item) => void;
  onBack: () => void;
  isLoading?: boolean;
}

const fileTypeColumnWidth = '50px';

function getFileIcon(extension: string, isDir?: boolean) {
  if (isDir) {
    return <BsFillFolderFill />;
  }

  if (['jpg', 'jpeg', 'png', 'tif', 'tiff', 'gif'].includes(extension)) {
    return <BsCardImage />;
  }
  if (['svg', 'eps', 'ai'].includes(extension)) {
    return (
      <Box transform="scaleX(-1)">
        <BsBezier2 />
      </Box>
    );
  }
  if (['project'].includes(extension)) {
    return (
      <Box transform="rotate(45deg) scaleY(-1)">
        <BsXDiamondFill />
      </Box>
    );
  }
  if (['template'].includes(extension)) {
    return (
      <Box transform="rotate(45deg) scaleY(-1)">
        <BsXDiamond />
      </Box>
    );
  }
  if (['pdf'].includes(extension)) {
    return <SiAdobeacrobatreader />;
  }
  if (['txt'].includes(extension)) {
    return <BsTextLeft />;
  }
  if (['rtf'].includes(extension)) {
    return <BsFonts />;
  }
  if (['js', 'tsx', 'jsx', 'py'].includes(extension)) {
    return <BsCodeSlash />;
  }
  if (['json'].includes(extension)) {
    return <VscJson />;
  }
  if (['csv'].includes(extension)) {
    return <BsFileEarmarkRuled />;
  }
  if (['xls', 'xlsx'].includes(extension)) {
    return <BsFileExcelFill />;
  }
  if (['zip', 'rar'].includes(extension)) {
    return <BsFileZipFill />;
  }
  if (['p7m', 'eml', 'msg', 'pst', 'edb', 'ost', 'eml', 'mbox'].includes(extension)) {
    return <BsEnvelope />;
  }
  if (
    ['mov', 'mp4', 'm4p', 'm4v', 'mpg', 'mpeg', 'm2v', 'mkv', 'webm', 'ogv', 'ogg', 'avi', 'rm', 'rmvb'].includes(
      extension,
    )
  ) {
    return <BsPlayBtn />;
  }
  if (['doc', 'docx'].includes(extension)) {
    return <BsFileWordFill />;
  }
  if (['ppt', 'pptx'].includes(extension)) {
    return <BsFillFilePptFill />;
  }

  return <RiFile2Line />;
}

export function FileSystem({
  files,
  selected,
  copied,
  showBackButton = true,
  onFileRename,
  onFileDelete,
  onFileMove,
  onFilePaste,
  onFileOpen,
  onFileConvert,
  onFileExtract,
  onFileCompress,
  onFileClick,
  onFileDoubleClick,
  onBack,
  isLoading = false,
}: FileSystemProps) {
  const [editItem, setEditItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState<{ value: Item; operation: string } | null>(null);
  const [clicked, setClicked] = useState('');
  const [loading, setLoading] = useState(false);

  const backTrBg = useColorModeValue('gray.100', 'gray.800');
  const loadingBg = useColorModeValue('whiteAlpha.800', 'blackAlpha.800');
  const borderColor = useColorModeValue('gray.300', 'gray.600');

  function handleLoading(stillLoading: boolean) {
    if (stillLoading) return;

    setTimeout(() => {
      setLoading(stillLoading);
    }, 666);
  }

  return (
    <>
      {loading && (
        <Box pos="fixed" top="0" left="0" bg={loadingBg} zIndex="1080" w="100vw" h="100vh">
          <Center alignItems="center" h="100vh">
            Preparing your files to download…
          </Center>
        </Box>
      )}
      <Box id="file-system" w="full">
        <AsyncLoader label="loading…" isLoading={isLoading}>
          <Table fontSize="sm" size="lg">
            <Thead>
              <Tr>
                <Th px={0} py={1.5} textAlign="center" w={fileTypeColumnWidth} borderColor={borderColor} />
                <Th px={2} py={1.5} borderColor={borderColor}>
                  Name
                </Th>
                {files?.length > 0 &&
                  files[0].metadata &&
                  Object.keys(files[0].metadata).map((m, i) => (
                    <Th borderColor={borderColor} key={i} px={2} py={0.5} fontSize="sm">
                      {m}
                    </Th>
                  ))}
              </Tr>
            </Thead>
            <Tbody>
              {showBackButton && (
                <Tr
                  borderBottom="1px solid"
                  pos="relative"
                  cursor="pointer"
                  onClick={onBack}
                  color="gray.500"
                  bg={backTrBg}
                >
                  <Td p={0} textAlign="center" w={fileTypeColumnWidth} borderColor={borderColor}>
                    <IconButton
                      aria-label="Previous folder"
                      variant="ghost"
                      size="md"
                      _hover={{ bg: 'none' }}
                      icon={<RiArrowUpLine fill="#888" />}
                    />
                  </Td>
                  <Td
                    px={2}
                    py={0}
                    borderColor={borderColor}
                    colSpan={files?.length > 0 && files[0].metadata ? Object.keys(files[0].metadata).length + 1 : 1}
                  >
                    Previous folder
                  </Td>
                </Tr>
              )}
              {files.map((file) => (
                <ContextMenu
                  key={file.name}
                  as="tr"
                  isNested
                  id={file.name}
                  value={file}
                  borderBottom="1px solid"
                  borderRadius={clicked ? 'sm' : 'none'}
                  boxShadow={clicked === file.name ? 'inset 0 0 1px 2px #00B5D8' : 'none'}
                  pos="relative"
                  cursor="pointer"
                  bg={selected.includes(file.name) ? 'cyan.500' : 'transparent'}
                  onClick={() => onFileClick(file)}
                  onDoubleClick={() => onFileDoubleClick(file)}
                  onMouseDown={() => setClicked(file.name)}
                  color={
                    // eslint-disable-next-line no-nested-ternary
                    selected.includes(file.name)
                      ? 'white'
                      : copied.items?.some((item: Item) => item.path === file.path && copied.cutting)
                      ? 'grey'
                      : 'inherit'
                  }
                  items={[
                    {
                      label: 'Extract here',
                      onClick: (item) => onFileExtract(item),
                      hidden: getFileExtension(file.name) !== 'zip',
                    },
                    {
                      label: 'Open with...',
                      onClick: (item) =>
                        setSelectedItem({
                          value: item,
                          operation: 'open',
                        }),
                      hidden: file.isDir,
                    },
                    {
                      label: 'Convert with...',
                      onClick: (item) =>
                        setSelectedItem({
                          value: item,
                          operation: 'convert',
                        }),
                      hidden: file.isDir,
                    },
                    {
                      label: 'Compress',
                      onClick: (item) => onFileCompress(item),
                    },
                    {
                      label: 'Copy as path',
                      onClick: (item) => navigator.clipboard.writeText(item.path),
                    },
                    {
                      label: 'Copy',
                      onClick: (item) => onFileMove(item, false),
                    },
                    {
                      label: 'Cut',
                      onClick: (item) => onFileMove(item, true),
                    },
                    {
                      label: 'Paste',
                      onClick: (item) => onFilePaste(item),
                      disabled: copied.items?.length === 0,
                    },
                    {
                      label: 'Download',
                      onClick: async () => {
                        setLoading(true);

                        if (selected.filter(() => selected.includes(clicked)).length < 1) {
                          let isDir = false;
                          let name = '';
                          const clickedToDownload = files
                            .filter((el) => clicked.includes(el.name))
                            .map((el) => {
                              isDir = el.isDir || false;
                              name = el.name;
                              return el.path;
                            });

                          if (isDir) {
                            orchestratorCrud
                              .createOne<Blob>(
                                'files/zip',
                                {
                                  paths: clickedToDownload,
                                },
                                {
                                  responseType: 'blob',
                                  headers: {
                                    'Content-type': 'application/octet-stream',
                                  },
                                },
                              )
                              .then((data) => {
                                saveAs(data, `${name}.zip`);
                                handleLoading(false);
                              });
                          } else {
                            orchestratorCrud
                              .getOne<Blob>('files', clickedToDownload[0], {
                                responseType: 'blob',
                              })
                              .then((data) => {
                                saveAs(data, clicked);
                                handleLoading(false);
                              });
                          }
                          return;
                        }

                        let isDir = false;
                        let name = 'false';
                        const filesToDownload = files
                          .filter((el) => selected.includes(el.name))
                          .map((el) => {
                            isDir = el.isDir || false;
                            name = el.name;
                            return el.path;
                          });

                        if (filesToDownload.length < 2) {
                          if (isDir) {
                            orchestratorCrud
                              .createOne<Blob>(
                                'files/zip',
                                {
                                  paths: filesToDownload,
                                },
                                {
                                  responseType: 'blob',
                                  headers: {
                                    'Content-type': 'application/octet-stream',
                                  },
                                },
                              )
                              .then((data) => {
                                saveAs(data, `${name}.zip`);
                                handleLoading(false);
                              });
                          } else {
                            filesToDownload.forEach((el, i) =>
                              orchestratorCrud
                                .getOne<Blob>('files', filesToDownload[0], {
                                  responseType: 'blob',
                                })
                                .then((data) => {
                                  saveAs(data, selected[i]);
                                  handleLoading(false);
                                }),
                            );
                          }
                        } else {
                          orchestratorCrud
                            .createOne<Blob>(
                              'files/zip',
                              {
                                paths: filesToDownload,
                              },
                              {
                                responseType: 'blob',
                                headers: {
                                  'Content-type': 'application/octet-stream',
                                },
                              },
                            )
                            .then((data) => {
                              saveAs(data, 'archive.zip');
                              handleLoading(false);
                            });
                        }
                      },
                    },
                    {
                      label: 'Rename',
                      onClick: (item) => setEditItem(item.name),
                    },
                    {
                      label: 'Delete',
                      onClick: (item) => {
                        // FUNZIONE DUPLICATA, CREARE FUNZIONE UTILS (onFileMove - FILE BUSINESS.JS)
                        let items = [];

                        if (selected.includes(file.name) && selected.length > 1) {
                          const selectedFiles = files.filter((_file) => selected.includes(_file.name));

                          items = selectedFiles;
                        } else {
                          items = [item];
                        }

                        onFileDelete(items.map((_item) => _item.path));
                      },
                    },
                  ]}
                >
                  <Td p={0} textAlign="center" w={fileTypeColumnWidth} borderColor={borderColor}>
                    <Button size="md" aria-label="" variant="ghost" _hover={{ bg: 'none' }}>
                      {file.type === 'cloud' ? (
                        <BsCloudy />
                      ) : (
                        getFileIcon(getFileExtension(file.name) || '', file.isDir)
                      )}
                    </Button>
                  </Td>
                  <Td px={2} py={0} borderColor={borderColor}>
                    <EditableItem
                      m="1"
                      value={file.name}
                      editMode={editItem === file.name}
                      onCancel={() => setEditItem(null)}
                      onRename={(el, newValue) => {
                        onFileRename(el, newValue);
                        setEditItem(null);
                      }}
                    >
                      <Text>{file.name}</Text>
                    </EditableItem>
                  </Td>
                  {file.metadata &&
                    Object.values(file.metadata).map((m, i) => (
                      <Td key={i} px={2} py={0} borderColor={borderColor} overflow="hidden" fontSize="sm">
                        {typeof m === 'boolean' ? (
                          <Box w="full" maxW="56px" h="2" borderRadius="full" bg={m ? 'green.900' : 'red.900'} />
                        ) : (
                          m
                        )}
                      </Td>
                    ))}
                </ContextMenu>
              ))}
            </Tbody>
            {files.length === 0 && <TableCaption fontStyle="italic">No files found</TableCaption>}
          </Table>
        </AsyncLoader>
      </Box>

      <ApplicationChooser
        isOpen={!!selectedItem && selectedItem.operation === 'open'}
        onOpenApplication={(application?: string) => {
          if (selectedItem?.value) {
            onFileOpen({
              item: selectedItem.value,
              editor: application,
            });
          }

          setSelectedItem(null);
        }}
        onClose={() => setSelectedItem(null)}
        item={selectedItem?.value}
      />

      <FileConverter
        isOpen={!!selectedItem && selectedItem.operation === 'convert'}
        onFileConvert={(values: { item: Item; from: string; to: string }) => {
          onFileConvert(values);
          setSelectedItem(null);
        }}
        onClose={() => setSelectedItem(null)}
        item={selectedItem?.value}
      />
    </>
  );
}
