import { DragEvent } from 'react';
import { HotKeys } from 'react-hotkeys-ce';
import { RiAddFill, RiDownloadCloud2Line, RiFileReduceFill, RiFileTextFill } from 'react-icons/ri';
import { ContextMenu } from 'shared/ContextMenu';
import { FileUpload } from 'shared/FileUpload';
import { Pagination } from 'shared/Pagination';
import { ViewList, ViewListProps } from 'shared/ViewList';
import { Box, Breadcrumb, BreadcrumbItem, Flex, Text } from '@chakra-ui/react';
import { useFileManagerBusiness } from './business';
import { Editor } from './Editor';
import { FileSystem } from './FileSystem';
import { SearchbarFileManager } from './SearchbarFileManager';
import { Item } from './types';

interface FileManagerProps extends Partial<ViewListProps> {
  selected?: string[];
  showFiles?: boolean;
  showDirectories?: boolean;
  editable?: boolean;
  multipleSelection?: boolean;
  showSearch?: boolean;
  showRange?: boolean;
  shortcutsEnabled?: boolean;
  path?: string;
  onPathChange?: (path: string) => void;
  onItemClick?: (item: Item) => void;
}

export function FileManager({
  selected,
  showFiles = true,
  showDirectories = true,
  editable = true,
  multipleSelection = true,
  showSearch = true,
  showRange = true,
  shortcutsEnabled = true,
  path = '/',
  onPathChange,
  onItemClick,
  ...rest
}: FileManagerProps) {
  const {
    state,
    handleFileCreate,
    handleDirectoryCreate,
    handleFileDelete,
    handleFileConvert,
    handleFileExtract,
    handleFileCompress,
    handleFilePaste,
    handleFileMove,
    handleFileRename,
    handleFileUpload,
    handleFileClick,
    handleFileDoubleClick,
    handlers,
    keyMap,
  } = useFileManagerBusiness(
    path,
    showFiles,
    showDirectories,
    multipleSelection,
    selected,
    editable,
    onPathChange,
    onItemClick,
  );

  function handleDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();

    const { files } = e.dataTransfer;

    if (files.length > 0) {
      handleFileUpload(files);
    }
  }

  return (
    <>
      {editable && state.edit ? (
        <Editor edit={state.edit} onClose={() => (state.edit = null)} />
      ) : (
        <ViewList
          title="File manager"
          isSearchEnabled={false}
          actions={[
            {
              icon: <RiAddFill />,
              label: 'File',
              isDisabled: state.path === '/' || state.loading,
              onClick: () => handleFileCreate(),
            },
            {
              icon: <RiAddFill />,
              label: 'Folder',
              isDisabled: state.path === '/' || state.loading,
              onClick: () => handleDirectoryCreate(),
            },
            {
              render: (
                <FileUpload
                  leftIcon={<RiDownloadCloud2Line />}
                  variant="ghost"
                  size="sm"
                  fontWeight="400"
                  label="Upload"
                  onUpload={handleFileUpload}
                  isDisabled={state.path === '/' || state.loading}
                />
              ),
            },
            {
              icon: state.showHidden ? <RiFileReduceFill /> : <RiFileTextFill />,
              label: state.showHidden ? 'Hide hidden files' : 'Show hidden files',
              onClick: () => (state.showHidden = !state.showHidden),
            },
          ]}
          {...rest}
        >
          {showSearch && (
            <Flex justifyContent="space-between" mt={0.5} mb={1}>
              <Box>
                <SearchbarFileManager
                  value={state.search}
                  onChange={(value: string) => {
                    state.search = value;
                    state.current = 0;
                  }}
                />
              </Box>
            </Flex>
          )}

          <Breadcrumb my={2}>
            <BreadcrumbItem>
              <Text>~</Text>
            </BreadcrumbItem>

            {state.path
              .split('/')
              .filter((n) => n)
              .map((folder, i) => (
                <BreadcrumbItem key={`${folder}_${i}`}>
                  <Text>{folder}</Text>
                </BreadcrumbItem>
              ))}
          </Breadcrumb>

          <HotKeys keyMap={shortcutsEnabled ? keyMap : {}} handlers={shortcutsEnabled ? handlers : {}} allowChanges>
            <Box
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDragLeave={(e) => e.preventDefault()}
              onDrop={handleDrop}
            >
              <ContextMenu
                id="fileManagerContextMenu"
                items={[
                  {
                    label: 'Paste',
                    onClick: () => {
                      handleFilePaste({
                        isDir: true,
                        path: state.path,
                      });
                    },
                    disabled: state.copied.items.length === 0,
                  },
                  {
                    label: 'New folder',
                    onClick: () => handleDirectoryCreate(),
                  },
                ]}
                h="full"
                w="full"
              >
                <FileSystem
                  files={state.files}
                  selected={state.selected}
                  copied={state.copied}
                  isLoading={state.loading}
                  showBackButton={state.path !== '/' && state.path !== '' && state.path !== '.'}
                  onFilePaste={handleFilePaste}
                  onFileExtract={handleFileExtract}
                  onFileCompress={handleFileCompress}
                  onFileMove={handleFileMove}
                  onFileDelete={handleFileDelete}
                  onFileRename={handleFileRename}
                  onFileOpen={({ item, editor }) =>
                    (state.edit = {
                      item,
                      editor,
                    })
                  }
                  onFileConvert={handleFileConvert}
                  onBack={() => {
                    if (state.path !== '/') {
                      const newPath = state.path.substr(0, state.path.lastIndexOf('/'));
                      state.path = newPath || '/';
                      onPathChange && onPathChange(newPath);
                    }
                  }}
                  onFileClick={handleFileClick}
                  onFileDoubleClick={handleFileDoubleClick}
                />
              </ContextMenu>
            </Box>
          </HotKeys>

          <Flex mt={4}>
            <Pagination
              total={state.total}
              current={state.current}
              itemsPerPage={state.itemsPerPage}
              onBack={() => (state.current -= 1)}
              onForward={() => (state.current += 1)}
              showRange={showRange}
              onRangeChange={(range) => (state.itemsPerPage = range)}
            />
          </Flex>
        </ViewList>
      )}
    </>
  );
}
