import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { IField } from 'constants/form';
import { BaseSelect } from 'shared/Form/BaseSelect';
import { gatewayCrud } from 'services/api';

export function GatewaySelect({
  field,
}: {
  field: IField & {
    fragment: string;
  };
}) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    gatewayCrud
      .getOne<[]>('services', field.fragment)
      .then((data) => {
        setOptions(data);
        setLoading(false);
      })
      .catch(() => setError(true));
  }, [field.fragment]);

  return (
    <>
      {error ? (
        <Text color="red" as="p" mt={2}>
          Error during retrieve informations
        </Text>
      ) : (
        <BaseSelect field={field} options={options} isLoading={loading} />
      )}
    </>
  );
}
