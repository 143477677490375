import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { areObjectEquals, deepClone } from 'ds4biz-core';
import { addEdge, Connection, Edge, Node, Position, XYPosition } from 'react-flow-renderer';
import { v4 as uuid } from 'uuid';
import { ITab, IProject, NodeData } from 'store/types/flow';
import { getTypeColor, Nullable } from 'utils/misc';
import { activeTab } from 'store/selectors/flow';
import { showToast } from 'shared/Toast';
import { writeStorage } from '@rehooks/local-storage';
import { orchestratorCrud } from 'services/api';
import { UNDOS_STORAGE } from 'constants/localStorage';

export interface FlowState extends IProject {
  status: {
    draggedNode: Nullable<{ name: string; isTemplate: boolean }>;
    clickedNode: Nullable<Node>;
    active: string;
    open: string[];
  };
}

const initialState: FlowState = {
  id: '',
  name: '',
  graphs: {},
  description: '',
  created_on: '',
  last_modify: '',
  status: {
    // Double clicked node with settigns open
    clickedNode: null,
    // Dragged from sidebar
    draggedNode: null,
    open: [],
    active: 'main',
  },
};

export function updateStoredOpen(projectId: string, open: string[]) {
  writeStorage(`loko.${projectId}.open`, open);
}

export function updateStoredActive(projectId: string, active: string) {
  writeStorage(`loko.${projectId}.active`, active);
}

export const flowStore = createSlice({
  name: 'flow',
  initialState,
  reducers: {
    setProject(_, action: PayloadAction<IProject>) {
      const project = action.payload;
      const projectTabs = Object.keys(project.graphs);

      let storedActive: string = localStorage.getItem(`loko.${project.id}.active`) || Object.keys(project.graphs)[0];
      let storedOpen: string[] = JSON.parse(localStorage.getItem(`loko.${project.id}.open`) || `["${storedActive}"]`);

      if (!projectTabs.includes(storedActive)) {
        storedActive = projectTabs[0];
      }

      storedOpen = storedOpen.filter((open) => projectTabs.includes(open));

      // In case the deleted tab was the only open, we add the new active tab as open
      if (!storedOpen.includes(storedActive)) {
        storedOpen.push(storedActive);
      }

      updateStoredActive(project.id, storedActive);
      updateStoredOpen(project.id, storedOpen);

      return {
        ...project,
        status: {
          clickedNode: null,
          draggedNode: null,
          open: storedOpen,
          active: storedActive,
        },
      };
    },
    setDraggedNode(state, action: PayloadAction<{ name: string; isTemplate: boolean } | null>) {
      state.status.draggedNode = action.payload;
    },
    setClickedNode(state, action: PayloadAction<Nullable<Node>>) {
      state.status.clickedNode = action.payload;
    },
    setTab(state, action: PayloadAction<ITab>) {
      const { nodes, edges } = action.payload;

      const payload = {
        nodes: nodes.map((node) => ({ ...node, selected: false, dragging: false })),
        edges: edges.map((edge) => ({ ...edge, selected: false, dragging: false })),
      };

      state.graphs[state.status.active] = payload;
    },
    addNode(state, action: PayloadAction<{ node: NodeData; position: XYPosition }>) {
      const { node, position } = action.payload;

      const newNode: Node = {
        id: uuid(),
        position,
        type: 'custom',
        dragHandle: '#nodeHandle',
        width: 150,
        height: 56,
        selectable: true,
        draggable: true,
        data: {
          ...node,
          options: {
            ...node.options,
            args: [
              {
                name: 'comment',
                type: 'area',
                label: 'Node comment',
                helper: 'Use it to explain the usage of this node',
              },
              {
                name: 'alias',
                type: 'text',
                label: 'Name',
                helper: 'Use this name as an alias',
              },
              {
                name: 'debug',
                type: 'boolean',
                label: 'Debug to Console',
                divider: true,
              },
              ...node.options.args,
            ],
          },
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      };

      state.graphs[state.status.active].nodes.push(newNode);
    },
    deleteNodes(state, action: PayloadAction<Node[]>) {
      const deletedNodes = action.payload;

      const nodeIds = deletedNodes.map((node) => node.id);

      state.graphs[state.status.active].nodes = state.graphs[state.status.active].nodes.filter(
        (node) => !nodeIds.includes(node.id),
      );
    },
    deleteEdges(state, action: PayloadAction<Edge[]>) {
      const deletedEdges = action.payload;

      const edgeIds = deletedEdges.map((edge) => edge.id);

      state.graphs[state.status.active].edges = state.graphs[state.status.active].edges.filter(
        (edge) => !edgeIds.includes(edge.id),
      );
    },
    addTab(state, action: PayloadAction<{ name?: string; navigateTo?: boolean } | undefined>) {
      let tabName = action.payload?.name;
      const navigateTo = action.payload?.navigateTo;

      // If tabName is not setted we insert 'Unitled' as default
      if (!tabName) {
        const tabs = Object.keys(state.graphs);
        let count = tabs.length;

        while (tabs.includes(`Untitled ${count}`)) {
          count += count;
        }

        tabName = `Untitled ${count}`;
      }

      state.graphs[tabName] = { nodes: [], edges: [] };

      if (navigateTo) {
        // Set new created tab as active
        flowStore.caseReducers.changeTab(state, { type: 'flow/changeTab', payload: tabName });
      }
    },
    renameTab(state, action: PayloadAction<{ oldName: string; newName: string }>) {
      const { oldName, newName } = action.payload;

      delete Object.assign(state.graphs, {
        [newName]: state.graphs[oldName],
      })[oldName];

      if (state.status.active === oldName) {
        state.status.active = newName;

        updateStoredActive(state.id, newName);
      }

      if (state.status.open.includes(oldName)) {
        const index = state.status.open.indexOf(oldName);
        state.status.open[index] = newName;

        updateStoredOpen(state.id, state.status.open);
      }
    },
    closeTab(state, action: PayloadAction<string>) {
      const closedTab = action.payload;
      const newOpenTabs = state.status.open.filter((tab) => tab !== closedTab);

      state.status.open = newOpenTabs;

      updateStoredOpen(state.id, newOpenTabs);

      if (state.status.active === closedTab) {
        // Set the last tab in the array as active
        state.status.active = newOpenTabs[newOpenTabs.length - 1];
        updateStoredActive(state.id, state.status.active);
      }
    },
    changeNodeStatus(state, action: PayloadAction<{ type: string; name: string; msg: string }>) {
      const event = action.payload;

      // Skip comment node
      const onlyNodes = activeTab(state).nodes.filter((node) => node.type === 'custom');

      for (let i = 0; i < onlyNodes.length; i++) {
        const node = onlyNodes[i];

        if (
          event.type === node.data.events?.type &&
          event.name === node.data.options.values[node.data.events?.field as string]
        ) {
          state.graphs[state.status.active].nodes[i].data.status = event.msg;
        }
      }
    },
    lockNode(state, _action: PayloadAction<{ id: string; action: 'lock' | 'release'; user: string }>) {
      const { id, action, user } = _action.payload;

      const nodeIndex = activeTab(state).nodes.findIndex((node) => node.id === id);

      const isLocking = action === 'lock' ? user : null;

      // https://stackoverflow.com/q/64419055/8114823
      const updatedNodes = deepClone(state.graphs[state.status.active].nodes);

      updatedNodes[nodeIndex] = {
        ...updatedNodes[nodeIndex],
        draggable: !isLocking,
        selectable: !isLocking,
        data: {
          ...updatedNodes[nodeIndex].data,
          locked: isLocking,
        },
      };

      state.graphs[state.status.active].nodes = updatedNodes;
    },
    changeHandleStatus(
      state,
      action: PayloadAction<{ nodeId: string; endpointId: string; source: 'input' | 'output' }>,
    ) {
      const { nodeId, endpointId, source } = action.payload;

      const nodeIndex = activeTab(state).nodes.findIndex((node) => node.id === nodeId);
      const endpointIndex = activeTab(state).nodes[nodeIndex].data[`${source}s`].findIndex(
        (endpoint: { id: string; label: string }) => endpoint.id === endpointId,
      );

      state.graphs[state.status.active].nodes[nodeIndex].data[`${source}s`][endpointIndex].closed =
        !state.graphs[state.status.active].nodes[nodeIndex].data[`${source}s`][endpointIndex].closed;
    },
    createEdge(state, action: PayloadAction<Connection>) {
      const connection = action.payload;

      const source = activeTab(state).nodes.find((node) => node.id === connection.source);
      const target = activeTab(state).nodes.find((node) => node.id === connection.target);

      // Different node
      if (source?.id !== target?.id) {
        const edge = {
          ...connection,
          data: {
            startColor: getTypeColor(source?.data.options.group),
            stopColor: getTypeColor(target?.data.options.group),
          },
        };

        const copiedEdges = deepClone(activeTab(state).edges);
        const newEdges = addEdge(edge, copiedEdges);

        state.graphs[state.status.active].edges = newEdges;
      }
    },
    updateNodeValues(state, action: PayloadAction<{ nodeId: string; data: { [key: string]: any } }>) {
      const { nodes } = activeTab(state);
      const { nodeId, data } = action.payload;

      const index = nodes.findIndex((node) => node.id === nodeId);

      // Safe check
      if (index === -1) {
        showToast({
          title: 'Component not found',
          description: 'Unable to save component settings',
          type: 'error',
        });

        return;
      }

      const node = nodes[index].data;

      state.graphs[state.status.active].nodes[index].data.configured = true;
      state.graphs[state.status.active].nodes[index].data.options.values = data;

      // UNLOCK NODE DOPO L'UPDATE DEI VALORI
      state.graphs[state.status.active].nodes[index].draggable = true;
      state.graphs[state.status.active].nodes[index].selectable = true;
      state.graphs[state.status.active].nodes[index].data.locked = null;

      if (data.inputs && !areObjectEquals(data.inputs, node.inputs)) {
        state.graphs[state.status.active].nodes[index].data.inputs = data.inputs;

        const inputsId = data.inputs.map((input: { label: string; id: string }) => input.id);

        // RIMUOVO GLI EDGES CHE ERANO COLLEGATI AD INPUTS ORA RIMOSSI DOPO IL SAVE
        state.graphs[state.status.active].edges = state.graphs[state.status.active].edges.filter(
          (edge) => edge.target !== nodes[index].id && !inputsId.includes(edge.targetHandle),
        );
      }

      if (data.outputs && !areObjectEquals(data.outputs, node.outputs)) {
        state.graphs[state.status.active].nodes[index].data.outputs = data.outputs;

        const outputsId = data.outputs.map((output: { label: string; id: string }) => output.id);

        // RIMUOVO GLI EDGES CHE ERANO COLLEGATI AD OUTPUTS ORA RIMOSSI DOPO IL SAVE
        state.graphs[state.status.active].edges = state.graphs[state.status.active].edges.filter(
          (edge) => edge.source !== nodes[index].id && !outputsId.includes(edge.sourceHandle),
        );
      }
    },
    deleteTab(state, action: PayloadAction<string>) {
      delete state.graphs[action.payload];

      const newOpen = state.status.open.filter((tab) => tab !== action.payload);

      if (state.status.active === action.payload) {
        const newActive = newOpen.length > 0 ? newOpen[0] : Object.keys(state.graphs)[0];

        state.status.active = newActive;
        updateStoredActive(state.id, newActive);
      }

      if (state.status.open.includes(action.payload)) {
        state.status.open = newOpen.length > 0 ? newOpen : [Object.keys(state.graphs)[0]];
        updateStoredOpen(state.id, state.status.open);
      }
    },
    changeTab(state, action: PayloadAction<string>) {
      state.status.active = action.payload;
      updateStoredActive(state.id, state.status.active);

      if (!state.status.open.includes(action.payload)) {
        state.status.open.push(action.payload);

        updateStoredOpen(state.id, state.status.open);
      }
    },
    undoFlow(state) {
      const storedUndos = JSON.parse(localStorage.getItem(UNDOS_STORAGE) || '[]');

      if (storedUndos.length > 0) {
        const lastUndo = storedUndos[storedUndos.length - 1];

        storedUndos.pop();

        writeStorage(UNDOS_STORAGE, storedUndos);
        updateStoredActive(state.id, lastUndo.status.active);
        updateStoredOpen(state.id, lastUndo.status.open);

        orchestratorCrud.overwriteOne('projects', state.id, lastUndo);

        return lastUndo;
      }
    },
    addComment(
      state,
      action: PayloadAction<{
        comment: string;
        fill: string;
        x: number;
        y: number;
        width: number;
        height: number;
      }>,
    ) {
      const { comment, fill, x, y, width = 100, height = 80 } = action.payload;

      const newComment: Node = {
        id: uuid(),
        position: { x, y },
        zIndex: -1,
        selectable: false,
        connectable: false,
        dragHandle: '#commentHandle',
        type: 'comment',
        width,
        height,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: {
          comment,
          fill,
          height,
          width,
        },
      };

      state.graphs[state.status.active].nodes.push(newComment);
    },
    editComment(
      state,
      action: PayloadAction<{ id: string; comment?: string; fill?: string; width?: number; height?: number }>,
    ) {
      const { id, comment, fill, width, height } = action.payload;

      const commentIndex = state.graphs[state.status.active].nodes.findIndex((node) => node.id === id);

      const newWidth = width ?? state.graphs[state.status.active].nodes[commentIndex].width;
      const newHeight = height ?? state.graphs[state.status.active].nodes[commentIndex].height;

      // @ts-ignore
      const newComment = comment ?? state.graphs[state.status.active].nodes[commentIndex].data.comment;
      // @ts-ignore
      const newFill = fill ?? state.graphs[state.status.active].nodes[commentIndex].data.fill;

      state.graphs[state.status.active].nodes[commentIndex] = {
        ...state.graphs[state.status.active].nodes[commentIndex],
        width: newWidth,
        height: newHeight,
        data: {
          ...state.graphs[state.status.active].nodes[commentIndex].data,
          // @ts-ignore
          width: newWidth,
          height: newHeight,
          comment: newComment,
          fill: newFill,
        },
      };
    },
    deleteComment(state, action: PayloadAction<string>) {
      const commentIndex = state.graphs[state.status.active].nodes.findIndex((node) => node.id === action.payload);

      state.graphs[state.status.active].nodes.splice(commentIndex, 1);
    },
    unlockNodes(state) {
      state.graphs[state.status.active].nodes = state.graphs[state.status.active].nodes.map((node) => ({
        ...node,
        draggable: true,
        selectable: true,
        data: { ...node.data, locked: null },
      }));
    },
  },
});

export const {
  setProject,
  setDraggedNode,
  setClickedNode,
  createEdge,
  deleteNodes,
  deleteEdges,
  changeNodeStatus,
  setTab,
  changeHandleStatus,
  updateNodeValues,
  lockNode,
  addTab,
  closeTab,
  deleteTab,
  addNode,
  changeTab,
  renameTab,
  undoFlow,
  addComment,
  editComment,
  deleteComment,
  unlockNodes,
} = flowStore.actions;
