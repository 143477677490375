import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useCompositeState } from 'ds4biz-core';
import { KeyMap } from 'react-hotkeys-ce';
import urljoin from 'url-join';
import { fileValidation, folderValidation, requiredLabel } from 'constants/form';
import { orchestratorCrud } from 'services/api';
import { useConfirm } from 'shared/ConfirmerProvider';
import { showToast } from 'shared/Toast';
import { copyFileAPI, createElementAPI, deleteElementAPI, getFilesAPI, renameFileAPI, uploadFileAPI } from './api';
import { Item } from './types';

const keyMap: KeyMap = {
  SHIFT_UP: { sequence: 'shift', action: 'keyup' },
  SHIFT_DOWN: { sequence: 'shift', action: 'keydown' },
  // @ts-ignore
  CTRL_UP: {
    // @ts-ignore
    sequences: ['ctrl', { sequence: 'option', action: 'keyup' }],
    action: 'keyup',
  },
  // @ts-ignore
  CTRL_DOWN: {
    // @ts-ignore
    sequences: ['ctrl', { sequence: 'option', action: 'keydown' }],
    action: 'keydown',
  },
  DELETE: { sequence: 'del', action: 'keyup' },
  ESC: 'escape',
  SELECT_ALL: ['command+a', 'ctrl+a'],
  COPY: ['command+c', 'ctrl+c'],
  CUT: ['command+x', 'ctrl+x'],
  PASTE: ['command+v', 'ctrl+v'],
  UP: 'up',
  DOWN: 'down',
  ENTER: { sequence: 'enter', action: 'keyup' },
};

interface StateProps {
  files: Item[];
  loading: boolean;
  path: string;
  type: string | null;
  refresh: boolean | null;
  selected: string[];
  copied: {
    cutting: boolean | undefined;
    items: Item[];
  };
  multiple: boolean;
  range: boolean;
  edit: {
    item: Item;
    editor?: string;
  } | null;
  convert: any;
  current: number;
  total: number;
  itemsPerPage: number;
  search: string;
  firstRun: boolean;
  showHidden: boolean;
}

export function useFileManagerBusiness(
  path: string,
  showFiles: boolean,
  showDirectories: boolean,
  multipleSelection: boolean,
  selected: string[] | undefined,
  editable: boolean,
  onPathChange?: (newPath: string) => void,
  onItemClick?: (item: Item) => void,
) {
  const state: StateProps = useCompositeState({
    files: [],
    loading: true,
    path,
    type: null,
    refresh: null,
    selected: [],
    copied: {
      cutting: false,
      items: [],
    },
    multiple: false,
    range: false,
    edit: null,
    convert: null,
    current: 0,
    total: 10,
    itemsPerPage: 20,
    search: '',
    firstRun: true,
    showHidden: true,
  });

  const confirm = useConfirm();

  function refreshFiles(search = true, startLoading?: boolean) {
    if (startLoading) {
      state.loading = true;
    }

    const start = state.current * state.itemsPerPage;
    const end = (state.current + 1) * state.itemsPerPage;

    const toSend: {
      [key: string]: any;
    } = {
      start,
      end,
      files: showFiles,
      directories: showDirectories,
    };

    if (search) {
      toSend.search = state.search;
    }

    getFilesAPI(state.path, {
      start,
      end,
      search: state.search,
      files: showFiles,
      directories: showDirectories,
      show_hidden: state.showHidden,
    })
      .then((response) => {
        state.total = response.headers?.range ? Number(response.headers.range) : 10;

        const { items, type } = response.data;

        state.files = items;
        state.type = type;
        state.loading = false;
      })
      .catch((e: AxiosError) => {
        // BLOCCO UN LOOP IN CASO NON AUTORIZZATO
        if (e.response?.status !== 401) {
          state.path = '/';

          if (onPathChange) {
            onPathChange('/');
          }
        }
      });

    state.selected = [];
    state.multiple = false;

    if (state.firstRun) {
      state.firstRun = false;
    }
  }

  function handleCopyShortcut(cutting: boolean) {
    const selectedFiles = state.files.filter((file) => state.selected.includes(file.name));

    state.copied = {
      items: selectedFiles,
      cutting,
    };

    state.selected = [];
  }

  function handleFileRename(file: string, newName: string) {
    renameFileAPI(urljoin(state.path, file), urljoin(state.path, newName)).then(() => refreshFiles());
  }

  function handleFilePaste(target: { isDir: true; path: string }) {
    if (target.isDir && state.copied.items.length > 0) {
      if (state.copied.cutting) {
        // CUT
        state.copied.items.forEach((item) => {
          renameFileAPI(item.path, target.path).then(() => refreshFiles());
        });
      } else {
        // COPY
        state.copied.items.forEach((item) => {
          const { path: copiedPath } = item;

          copyFileAPI(copiedPath, target.path).then(() => {
            refreshFiles();
          });
        });
      }

      state.copied = {
        items: [],
        cutting: false,
      };
    }
  }

  function handleFileMove(item: Item, cutting?: boolean) {
    let items = [];

    if (state.selected.includes(item.name) && state.selected.length > 1) {
      const selectedFiles = state.files.filter((file) => state.selected.includes(file.name));

      items = selectedFiles;
    } else {
      items = [item];
    }

    state.copied = {
      cutting,
      items,
    };

    state.selected = [];
  }

  async function handleFileCreate() {
    const { name } = await confirm({
      title: 'New file',
      settings: [
        {
          name: 'name',
          placeholder: 'New file name',
          hideLabel: true,
          type: 'text',
          validation: fileValidation,
        },
      ],
    });

    if (name) {
      createElementAPI(urljoin(state.path, name), false).then(() => refreshFiles());
    }
  }

  async function handleDirectoryCreate() {
    const { input } = await confirm({
      title: 'New folder',
      settings: [
        {
          name: 'input',
          placeholder: 'Folder name',
          hideLabel: true,
          type: 'text',
          validation: folderValidation,
        },
      ],
    });

    if (input) {
      createElementAPI(urljoin(state.path, input), true).then(() => refreshFiles());
    }
  }

  function handleFileClick(item: Item) {
    const updatedSelected = [...state.selected];

    if (updatedSelected.includes(item.name)) {
      if (state.multiple) {
        state.selected = updatedSelected.filter((filename) => filename !== item.name);
      }
    } else if (state.multiple && multipleSelection) {
      state.selected = [...state.selected, item.name];
    } else if (state.range && multipleSelection) {
      const clickedFileIndex = state.files.findIndex((file) => file.name === item.name) + 1;

      if (state.selected.length === 0) {
        const selectedFiles = state.files.slice(0, clickedFileIndex);

        state.selected = selectedFiles.map((file) => file.name);
      } else {
        let lastIndex = 0;

        state.selected.forEach((name) => {
          const index = state.files.findIndex((file) => file.name === name);

          if (index > lastIndex) {
            lastIndex = index;
          }
        });

        const selectedFiles = state.files.slice(lastIndex, clickedFileIndex);

        state.selected = selectedFiles.map((file) => file.name);
      }
    } else state.selected = [item.name];

    onItemClick && onItemClick(item);
  }

  function handleFileDoubleClick(item: Item) {
    if (item.isDir) {
      state.search = '';
      state.path = item.path;
      state.type = item.type;
      onPathChange && onPathChange(item.path);
    } else if (editable) state.edit = { item };
  }

  function handleFileUpload(files: FileList | null) {
    if (files) {
      showToast({
        title: 'Uploading file...',
        type: 'info',
      });

      uploadFileAPI(state.path, Array.from(files)).then(() => {
        showToast({
          title: 'File uploaded!',
          type: 'success',
        });
        refreshFiles();
      });
    }
  }

  async function handleFileDelete(files: string[]) {
    if (files?.length > 0) {
      if (
        await confirm({
          title: 'Confirm deletion',
          body: 'Are you sure to delete selected file/folder?',
          confirmLabel: 'Delete',
        })
      ) {
        deleteElementAPI(files).then(() => refreshFiles());
      }
    }
  }

  function handleFileConvert({ item, from, to }: { item: Item; from: string; to: string }) {
    showToast({ title: 'We are converting your file...', type: 'info' });

    orchestratorCrud
      .getAll(urljoin('files', 'convert'), {
        params: {
          fname: item.path,
          input_format: from,
          output_format: to,
        },
      })
      .then(() => {
        refreshFiles();
        showToast({ title: 'File successfully converted' });
      });
  }

  function handleFileExtract(item: Item) {
    orchestratorCrud.getAll(urljoin('files', 'unzip'), { params: { file: item.path } }).then(() => refreshFiles());
  }

  async function handleFileCompress(item: Item) {
    const { name } = await confirm({
      title: 'Compress file/files',
      settings: [
        {
          name: 'name',
          placeholder: 'Compressed folder name',
          hideLabel: true,
          type: 'text',
          validation: {
            required: requiredLabel,
          },
        },
      ],
      confirmLabel: 'Compress',
    });

    if (name) {
      const files = state.files.filter((el) => state.selected.includes(el.name)).map((el) => el.path);

      const paths = files.length === 0 ? [item.path] : files;

      orchestratorCrud
        .createOne(urljoin('files', 'zip'), { paths }, { params: { folder_name: name } })
        .then(() => refreshFiles());
    }
  }

  const handlers:
    | {
        [key: string]: (keyEvent?: KeyboardEvent | undefined) => void;
      }
    | undefined = {
    UP: () => {
      if (state.files.length === 0) {
        return;
      }

      if (state.selected.length === 0) {
        // SELEZIONO L'ULTIMO ELEMENTO
        state.selected = [state.files[state.files.length - 1].name];
      } else {
        let lastIndex = 0;

        state.selected.forEach((name: string) => {
          const index = state.files.findIndex((file) => file.name === name);

          if (index > lastIndex) {
            lastIndex = index;
          }
        });

        // UNDEFINED SE SIAMO ARRIVATI ALL'INIZIO DELLA LISTA DI FILE
        if (state.files[lastIndex - 1]) {
          state.selected = [state.files[lastIndex - 1].name];
        }
      }
    },
    DOWN: () => {
      if (state.files.length === 0) {
        return;
      }

      if (state.selected.length === 0) {
        // SELEZIONO L'ULTIMO ELEMENTO
        state.selected = [state.files[0].name];
      } else {
        let lastIndex = 0;

        state.selected.forEach((name: string) => {
          const index = state.files.findIndex((file) => file.name === name);

          if (index > lastIndex) {
            lastIndex = index;
          }
        });

        // UNDEFINED SE SIAMO ARRIVATI ALLA FINE DELLA LISTA DI FILE
        if (state.files[lastIndex + 1]) {
          state.selected = [state.files[lastIndex + 1].name];
        }
      }
    },
    SHIFT_DOWN: () => (state.range = true),
    SHIFT_UP: () => (state.range = false),
    CTRL_DOWN: () => (state.multiple = true),
    CTRL_UP: () => (state.multiple = false),
    COPY: (e: KeyboardEvent | undefined) => {
      e && e.preventDefault();

      handleCopyShortcut(false);
    },
    CUT: (e: KeyboardEvent | undefined) => {
      e && e.preventDefault();

      handleCopyShortcut(true);
    },
    PASTE: (e: KeyboardEvent | undefined) => {
      e && e.preventDefault();

      handleFilePaste({ isDir: true, path: state.path });
    },
    SELECT_ALL: (e: KeyboardEvent | undefined) => {
      e && e.preventDefault();

      state.selected = state.files.map((file) => file.name);
    },
    ESC: () => (state.selected = []),
    DELETE: () => {
      const files = state.files.filter((el) => state.selected.includes(el.name)).map((el) => el.path);

      handleFileDelete(files);
    },
    ENTER: () => {
      if (state.selected.length === 1) {
        const name = state.selected[0];
        const item = state.files.find((entry) => entry.name === name);

        if (item) {
          if (item.isDir) {
            state.path = item.path;
            state.type = item.type;

            if (onPathChange) {
              onPathChange(item.path);
            }
          } else if (editable) state.edit = { item };
        }
      }
    },
  };

  useEffect(() => {
    if (path && state.path !== path) {
      if (state.edit) {
        state.edit = null;
      }

      state.path = path;
    }
  }, [path]);

  useEffect(() => {
    refreshFiles();
  }, [state.current, state.search, state.showHidden, showFiles, showDirectories]);

  useEffect(() => {
    if (!state.firstRun) {
      state.loading = true;
      state.current = 0;
      state.search = '';

      refreshFiles(false);
    }
  }, [state.path, state.itemsPerPage]);

  useEffect(() => {
    if (selected) {
      state.selected = selected;
    }
  }, [selected]);

  return {
    state,
    handleDirectoryCreate,
    handleFileCreate,
    handleFileUpload,
    handleFileDelete,
    handleFileExtract,
    handleFileCompress,
    handleFileConvert,
    handleFilePaste,
    handleFileClick,
    handleFileDoubleClick,
    handleFileRename,
    handleFileMove,
    refreshFiles,
    keyMap,
    handlers,
  };
}
